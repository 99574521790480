import { Controller } from "@hotwired/stimulus";

type tAutocompleteSuggestion = google.maps.places.AutocompleteSuggestion;
type AddressComponentMap = {
	route?: string;
	street_number?: string;
	state?: string;
	city?: string;
	zip?: string;
};

export default class extends Controller {
	static targets = [
		"results",
		"addressLine1Input",
		"addressLine2Input",
		"cityInput",
		"stateInput",
		"zipInput",
	];

	declare place: typeof google.maps.places.Place;
	declare autocompleteSessionToken: typeof google.maps.places.AutocompleteSessionToken;
	declare autocompleteSuggestion: typeof google.maps.places.AutocompleteSuggestion;

	declare readonly resultsTarget: HTMLDivElement;
	declare readonly addressLine1InputTarget: HTMLInputElement;
	declare readonly addressLine2InputTarget: HTMLInputElement;
	declare readonly cityInputTarget: HTMLInputElement;
	declare readonly stateInputTarget: HTMLSelectElement;
	declare readonly zipInputTarget: HTMLInputElement;

	async connect() {
		const { Place, AutocompleteSessionToken, AutocompleteSuggestion } =
			(await window.google.maps.importLibrary(
				"places"
			)) as google.maps.PlacesLibrary;
		this.place = Place;
		this.autocompleteSessionToken = AutocompleteSessionToken;
		this.autocompleteSuggestion = AutocompleteSuggestion;
	}

	async searchPlaces({ target }: InputEvent) {
		const input = (target as HTMLInputElement).value;
		if (!input) return;

		const sessionToken = new this.autocompleteSessionToken();

		const request = {
			input,
			includedPrimaryTypes: ["street_address"],
			language: "en-US",
			region: "us",
			sessionToken,
		};

		const { suggestions } =
			await this.autocompleteSuggestion.fetchAutocompleteSuggestions(request);

		this.renderResults(input, suggestions);
	}

	renderResults(input: string, suggestions: tAutocompleteSuggestion[]) {
		this.resultsTarget.innerHTML = "";

		if (!input) {
			this.hideResults();
		} else {
			this.resultsTarget.classList.remove("hidden");
		}

		if (suggestions.length) {
			suggestions.forEach((suggestion) => {
				const suggestionElement = document.createElement("div");
				suggestionElement.classList.add(
					"flex",
					"flex-col",
					"w-full",
					"border-b",
					"p-2",
					"cursor-pointer"
				);
				suggestionElement.innerHTML = `
		  		<div>${suggestion.placePrediction?.mainText?.toString()}</div>
		  		<div class="text-sm">${suggestion.placePrediction?.secondaryText?.toString()}</div>
		  	`;

				suggestionElement.addEventListener("click", () => {
					this.selectPlace(suggestion.placePrediction?.toPlace());
				});
				this.resultsTarget.appendChild(suggestionElement);
			});
		}

		if (input && !suggestions.length) {
			this.resultsTarget.innerHTML = `<div class="flex w-full h-10 items-center justify-center">No results for "${input}"</div>`;
		}
	}

	async selectPlace(place?: google.maps.places.Place) {
		if (!place) return;

		await place.fetchFields({
			fields: ["addressComponents"],
		});

		const componentMap: AddressComponentMap = (
			place.addressComponents || []
		).reduce((acc, cur) => {
			const type = this.humanizeAddressComponentType(cur.types[0]);
			// @ts-ignore
			acc[type] = cur.longText;

			return acc;
		}, {});

		this.hideResults();

		this.addressLine1InputTarget.value = `${componentMap.street_number} ${componentMap.route}`;
		this.cityInputTarget.value = componentMap.city || "";
		this.stateInputTarget.value =
			Array.from(this.stateInputTarget.options).find(
				(o) => o.text == componentMap.state
			)?.value || "";
		this.zipInputTarget.value = componentMap.zip || "";
	}

	selectFirstResult(e: KeyboardEvent) {
		e.preventDefault();
		this.resultsTarget.children[0].dispatchEvent(new MouseEvent("click"));
	}

	hideResults() {
		this.resultsTarget.classList.add("hidden");
		this.resultsTarget.innerHTML = "";
	}

	humanizeAddressComponentType(type: string) {
		switch (type) {
			case "administrative_area_level_1":
				return "state";
			case "administrative_area_level_2":
				return "county";
			case "postal_code":
				return "zip";
			case "locality":
				return "city";
			default:
				return type;
		}
	}
}
